<template>
  <b-modal
    v-model="editShowModal"
    ok-title="Confirmer"
    cancel-title="Annuler"
    @ok="confimrEdit"
    title="Modification rapide"
  >
    <b-row class="my-1">
      <b-col md="3">
        <label for="input-small" style="font-weight: bold">Nom</label>
      </b-col>

      <b-col md="9">
        <b-form-input
          id="input-small"
          v-model="editName"
          readonly
          size="md"
        ></b-form-input>
      </b-col>
    </b-row>

    <b-row class="my-1">
      <b-col sm="3">
        <label for="input-small" style="font-weight: bold">Email</label>
      </b-col>

      <b-col sm="9">
        <b-form-input
          readonly
          id="input-small"
          v-model="editEmail"
          size="md"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row class="my-1">
      <b-col md="3">
        <label for="input-small" style="font-weight: bold">Role</label>
      </b-col>
      <b-col md="9">
        <b-form-select v-model="editRole" :options="role"></b-form-select>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { BRow, BCol, BFormInput, BFormSelect } from "bootstrap-vue";
export default {
  data() {
    return {
      editShowModal: this.showModal,
      editName: this.displayName,
      editEmail: this.email,
      editRole: null,
      role: [
        { value: "admin", text: "Administrateur" },
        { value: "editor", text: "Editeur" },
        { value: "gestionner", text: "Gestionnaire de boutique" },
      ],
    };
  },
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
  },
  props: {
    showModal: Boolean,
    displayName: String,
    email: String,
    uid: String,
    customClaims: Object,
  },
  methods: {
    confimrEdit() {
      this.$emit("ConfirmEdit", {
        customClaims: this.editRole,
        uid: this.uid,
      });
    },
  },
};
</script>
