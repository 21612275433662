<template>
  <div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div>
        <div class="card mb-4">
          <div class="card-header"></div>
          <div class="card-body">
            <h4>Filtrage</h4>
            <div class="row" style="margin-top: 30px">
              <div class="col-12 col-lg-3">
                <h6>Role :</h6>
                <v-select
                  :options="role"
                  v-model="roleFiltre"
                  label="formattedName"
                >
                </v-select>
              </div>

              <div class="col-12 col-lg-3" style="margin-top: 23px">
                <div class="row">
                  <button
                    class="col-6 col-lg-6 btn btn-primary ml-1"
                    @click="getUsersFilter()"
                  >
                    Filtrage Users
                  </button>

                  <button
                    class="col-4 col-lg-4 btn btn-secondary ml-1"
                    @click="clearFiltre()"
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-table
        :items="listUser"
        :fields="fields"
        striped
        hover
        responsive
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong style="margin-left: 5px">Loading...</strong>
          </div>
        </template>

        <template #cell(customClaims)="row">
          <b-badge
            pill
            variant="info"
            class="text-capitalize"
            v-if="row.item.customClaims.admin == true"
          >
            Administrateur
          </b-badge>
          <b-badge
            pill
            variant="success"
            class="text-capitalize"
            v-if="row.item.customClaims.editor == true"
          >
            Editeur
          </b-badge>
          <b-badge
            pill
            variant="danger"
            class="text-capitalize"
            v-if="row.item.customClaims.gestionner == true"
          >
            Gestionnaire de boutique
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.id}-edit-icon`"
              icon="EditIcon"
              class="mx-1"
              @click="getUserInfo(data.item)"
            />
            <b-tooltip
              title="Modification"
              :target="`invoice-row-${data.item.id}-edit-icon`"
            />

            <feather-icon
              :id="`invoice-row-${data.item.uid}-slash-icon`"
              icon="SlashIcon"
              @click="showMsgBox(data.item.uid)"
            />
            <b-tooltip
              title="Désactiver"
              :target="`invoice-row-${data.item.uid}-slash-icon`"
            />
          </div>
        </template>
      </b-table>
      <div>
        <EditRole
          v-if="showModal"
          :showModal="showModal"
          :displayName="userInfo.displayName"
          :email="userInfo.email"
          :uid="userInfo.uid"
          @ConfirmEdit="changeRole"
        />
      </div>
    </b-overlay>
  </div>
</template>
<script>
import {
  BTable,
  BButton,
  BFormInput,
  BSpinner,
  BTooltip,
  BBadge,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import { getAuth } from "firebase/auth";
import EditRole from "../../views/Users/EditRole.vue";

export default {
  components: {
    vSelect,
    BTable,
    BFormInput,
    BButton,
    BSpinner,
    BTooltip,
    BBadge,
    EditRole,
    BOverlay,
  },
  data() {
    return {
      role: ["Administrateur", "Editeur", "Gestionnaire de boutique"],
      fields: [
        {
          label: "Nom",
          key: "displayName",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Email",
          key: "email",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Role",
          key: "customClaims",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Actions",
          key: "actions",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      isBusy: false,
      listUser: [],
      roleFiltre: null,
      boxMsg: "",
      showModal: false,
      userInfo: [],
      loading: false,
    };
  },
  methods: {
    async getUserInfo(item) {
      this.userInfo = item;
      this.showModal = !this.showModal;
    },
    //Permet de recuperer la liste des utilisateurs
    async getUsers() {
      this.isBusy = true;
      try {
        const getUserFunction = firebase
          .functions()
          .httpsCallable("listAllUsers");
        const response = await getUserFunction({
          maxResults: 1000,
        });

        this.listUser =
          response.data.users.filter((user) => user.disabled === false) || [];

        this.isBusy = false;
      } catch (error) {
        alert(
          "Une erreur s'est produite lors de la récupération des utilisateurs:",
          error
        );
        this.isBusy = false;
      }
    },
    //Permet de filtrer la liste des utilisateurs par role
    async getUsersFilter() {
      this.isBusy = true;
      try {
        const getUserFunction = firebase
          .functions()
          .httpsCallable("listAllUsers");
        const response = await getUserFunction({
          maxResults: 1000,
        });
        if (this.roleFiltre == "Administrateur") {
          this.listUser =
            response.data.users.filter(
              (user) =>
                user.customClaims.admin === true && user.disabled === false
            ) || [];
        } else if (this.roleFiltre == "Editeur") {
          this.listUser =
            response.data.users.filter(
              (user) =>
                user.customClaims.editor === true && user.disabled === false
            ) || [];
        } else if (this.roleFiltre == "Gestionnaire de boutique") {
          this.listUser =
            response.data.users.filter(
              (user) =>
                user.customClaims.gestionner === true && user.disabled === false
            ) || [];
        }
      } catch (error) {
        alert(
          "Une erreur s'est produite, veuillez réessayer plus tard. Merci",
          error
        );
      }
      this.isBusy = false;
    },
    //Permet de réinitialiser le filtre
    async clearFiltre() {
      this.roleFiltre = null;
      this.getUsers();
    },
    //Permet de desactiver un compte
    async disableUser(userUid) {
      this.isBusy = true;
      const auth = getAuth();
      const user = auth.currentUser;

      if (userUid !== user.uid) {
        try {
          const uidValue = userUid;
          const disableUserFunction = firebase
            .functions()
            .httpsCallable("disableUser");
          const response = await disableUserFunction({ uid: uidValue });
          if (response.data.success) {
            this.getUsers();
            this.makeToastMsg("info");
          } else {
            alert(
              "Une erreur s'est produite, veuillez réessayer plus tard. Merci:",
              response.data.error
            );
          }
        } catch (error) {
          alert(
            "Une erreur s'est produite, veuillez réessayer plus tard. Merci:",
            error
          );
        }
      } else {
        alert(
          "Impossible de désactiver cet utilisateur, car est déjà connecté"
        );
      }
      this.isBusy = false;
    },
    //Permet de modifier le role d'un utilisateur
    async changeRole(info) {
      this.isBusy = true;
      const auth = getAuth();
      const user = auth.currentUser;
      if (info.uid !== user.uid) {
        try {
          const changeRoleUserFunction = firebase
            .functions()
            .httpsCallable("changeUserRole");
          const data = {
            uid: info.uid,
            role: info.customClaims,
          };
          const response = await changeRoleUserFunction(data);
          if (response.data.success) {
            this.confirmMsgChangeRole("info");
            this.getUsers();
          } else {
            alert(
              "Une erreur s'est produite pendant la mise à jour du rôle de l'utilisateur, veuillez réessayer plus tard:",
              response.data.error
            );
          }
        } catch (error) {
          alert(
            "Une erreur s'est produite, veuillez réessayer plus tard. Merci:",
            error
          );
        }
      } else {
        alert(
          "Impossible de modifier le role de cet utilisateur, car est deja connecter"
        );
      }
      this.isBusy = false;
    },
    //Message pour confirmer ou non la desactivation d'un utilisateur
    showMsgBox(userUid) {
      this.boxMsg = "";
      this.$bvModal
        .msgBoxConfirm("Êtes-vous sûr de vouloir désactiver l'utilisateur ?", {
          title: "Confirmation",
          size: "sm",
          okVariant: "primary",
          okTitle: this.loading ? "Loading..." : "Confirmer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          this.boxMsgDelete = value;
          if (value) {
            if (value) {
              this.loading = true;
              this.disableUser(userUid).then(async () => {
                this.loading = false;
              });
            }
          }
        });
    },
    makeToastMsg(variant = null) {
      this.$bvToast.toast("L'utilisateur a bien été désactiver", {
        title: `Utilisateur ${variant || "default"}`,
        variant,
        solid: true,
        href: this.permalink,
      });
    },
    confirmMsgChangeRole(variant = null) {
      this.$bvToast.toast("Le rôle a bien était modifier", {
        title: `Utilisateur ${variant || "default"}`,
        variant,
        solid: true,
        href: this.permalink,
      });
    },
  },
  created() {
    this.getUsers();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
